<template>
  <section class="container pb-8 ">
    <div class="container">
      <div class="error-bg mb-3 mb-md-8">
        <div>您訪問的連結可能發生故障或已被移除<br />返回首頁或重新進行操作<br /></div>
      </div>
      <!-- 返回首頁 -->
      <div class="d-flex justify-content-end">
        <router-link class="btn btn-primary btn-hover-shadow px-6 py-2 py-md-3" to="/">
          返回首頁
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
